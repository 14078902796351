import { useEffect } from 'react'

const BaixarApk = () => {
  useEffect(() => {
    window.location = 'https://ahazou.app.goo.gl/site-ahz'
  }, [])

  return null
}

export default BaixarApk
